<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
              Not Found
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted() {

        }
    }
</script>
