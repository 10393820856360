export default [
    {
        path: '/',
        redirect: '/orders/list'
    },
    {
        path: '/favicon.ico',
        redirect: '/orders/list'
    },
    {
        path: '/dashboard',
        redirect: '/orders/list'
    },
    {
        path: '/orders/list',
        name: 'orders-list',
        component: () => import( './pages/orders/OrderList.vue')
    },
    {
        path: '/orders/:id',
        name: 'show-order',
        props: true,
        component: () => import('./pages/orders/Order.vue')
    },
    {
        path: '/report/orders',
        name: 'report-orders',
        props: true,
        component: () => import('./pages/reports/OrdersReport.vue')
    },
    {
        path: '/dashboards/orders',
        name: 'orders-dashboard',
        props: true,
        component: () => import('./pages/dashboards/OrdersDashboard.vue')
    },
    {
        path: '/report/campanhas',
        name: 'campaigns',
        props: true,
        component: () => import('./pages/reports/CampaignsReport.vue')
    },
    {
        path: '/report/workflow',
        name: 'workflow',
        component: () => import('./pages/reports/OrdersWorkflowReport.vue')
    },
    {
        path: '/report/pendencies-mei',
        name: 'pendenciesMei',
        props: true,
        component: () => import('./pages/reports/PendenciesMeiReport.vue')
    },
    {
        path: '/tools/pendencies',
        name: 'mei-pendencies',
        props: true,
        component: () => import('./pages/tools/Pendencies.vue')
    },
    {
        path: '/logs/list',
        name: 'logs',
        props: true,
        component: () => import('./pages/logs/LogList.vue')
    },
    {
        path: '/logs/:id',
        name: 'show-log',
        props: true,
        component: () => import('./pages/logs/Log.vue')
    },
    {
        path: '/subscriptions/create',
        name: 'create-subscription',
        props: true,
        component: () => import('./pages/subscriptions/SubscriptionCreate.vue')
    },
    {
        path: '/subscriptions/list',
        name: 'subscription-list',
        props: true,
        component: () => import('./pages/subscriptions/SubscriptionList.vue')
    },
    {
        path: '/plans',
        name: 'plans-list',
        props: true,
        component: () => import('./pages/plans/List.vue')
    },
    {
        path: '/plans/:id',
        name: 'plans-show',
        props: true,
        component: () => import('./pages/plans/Show.vue')
    },
    {
        path: '/services',
        name: 'services-list',
        props: true,
        component: () => import('./pages/services/List.vue')
    },
    {
        path: '/services/:id',
        name: 'services-show',
        props: true,
        component: () => import('./pages/services/Show.vue')
    },
    { path: '*', component: require('./components/NotFound.vue').default },
];
